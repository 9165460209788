import { Dismiss } from "flowbite";
import { Controller } from "@hotwired/stimulus";

export default class extends Controller {

  static targets = ["close"];

  connect() {
    const options = {
      transition: 'transition-opacity',
      duration: 1000,
      timing: 'ease-out'
    }
    this.dismiss = new Dismiss(this.element, this.closeTarget, options);
  }

  hide() {
    this.dismiss.hide();
  }
}
