import { Controller } from "@hotwired/stimulus";
import { get } from "@rails/request.js";

// Connects to data-controller="fact-selector"
// TODO: Improve this code to render the fact selector only when the user is creating a new student fact
// We should map the form instead of the select input
export default class extends Controller {
  detailsContainer() {
    return document.querySelector("#fact-details");
  }

  factSelectorContainer() {
    return document.querySelector("#fact-selector");
  }

  factCategoryContainer() {
    return document.querySelector("#fact-category");
  }

  showFact(event) {
    if (event.target.value === "transgression") {
      return this.showFactCategory(event);
    }

    return this.showFactSelector(event);
  }

  async renderFactSelector(kind = "transgression", category = "scholar", items = 200) {
    const renderKind = kind === "transgression" ? "Selecione a Transgressão" : "Selecione o FOP";
    const container = this.factSelectorContainer();
    container.innerHTML = `
      <div class="form-group">
        <label for="student_fact_fact_id" class="form-label">${renderKind}</label>
        <select id="student_fact_fact_id" class="form-control" name="student_fact[fact_id]" data-controller="fact-selector" data-action="change->fact-selector#renderDetails" data-kind="${kind}">
          <option value="">Selecione uma opção</option>
          ${await this.mountFactOptions(kind, category, items)}
        </select>
      </div>
    `;
  }

  // This function will mount the the options for the fact selector
  async mountFactOptions(kind = "transgression", category = "scholar", items = 200) {
    const data = await this.getfactsData(kind, category, items);
    return data.map((item) => `<option value="${item.id}">${item.title}</option>`).join("");
  }

  showFactSelector(event) {
    this.detailsContainer().innerHTML = "";
    const isEditMode = event.target.getAttribute("data-edit-mode") === "true";
    const kind = event.target.getAttribute("data-fact-kind") || event.target.value || "transgression";
    const items = event.target.getAttribute("data-items") || 200;
    const category = event.target.getAttribute("data-category") || event.target.value || "scholar";
    return this.renderFactSelector(kind, category, items);
  }

  showFactCategory(event) {
    const container = this.factCategoryContainer();
    container.innerHTML = `
      <div class="form-group">
        <label for="category" class="form-label">Selecione a Categoria</label>
        <select
          id="category"
          class="form-control"
          name="category"
          data-controller="fact-selector"
          data-action="change->fact-selector#showFactSelector"
          data-loading="${event.target.getAttribute("data-loading")}"
          data-fact-kind="${event.target.getAttribute("data-fact-kind") || event.target.value}"
          data-edit-mode="${event.target.getAttribute("data-edit-mode") || false}"
        >
          <option value="">Selecione uma opção</option>
          <option value="scholar">Escolar</option>
          <option value="disciplinary">Disciplinar</option>
        </select>
      </div>
    `;
  }

  // This function will grab facts data from the API
  // Kind can be transgression or fop
  // Items is the number of items to fetch
  async getfactsData(kind = "transgression", category = "scholar", items = 200) {
    const response = await get(`/app/${kind}s.json`, { query: { items, category }});
    return await response.json;
  }

  async getFactDetails(kind, id) {
    const response = await get(`/app/${kind}s/${id}.json`);
    return await response.json;;
  }

  async renderDetails(event) {
    const factId = event.target.value;
    const kind = event.target.getAttribute("data-kind");
    const container = this.detailsContainer();
    if (factId) {
      const data = await this.getFactDetails(kind, factId);
      if (kind === "transgression") {
        container.innerHTML = `<h3 class="text-xl font-bold mb-5">O aluno perderá <strong class="text-red-500"> ${data.point} </strong> pontos</h3>`;
      } else if (kind === "fop") {
        container.innerHTML = `<h3 class="text-xl font-bold mb-5">O aluno ganhará <strong class="text-green-500"> ${data.point} </strong> pontos</h3>`;
      }
    } else {
      container.innerHTML = "";
    }
  }

  connect() {
    // this.showFactSelector(null, true);
  }
}
